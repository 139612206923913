<template>
    <b-sidebar id="sidebar" bg-variant="default" @change="updateBodyClass" width="180px" shadow no-close-on-route-change visible>
        <template v-slot:title>
            <img src="../assets/img/logo.png" alt="Sidebar" style="width: 80%; height: 20%; margin-top: 4px;">
        </template>
        <b-list-group flush>
            <template v-for="(item, index) in menuItems">
                <b-list-group-item :active="$route.meta.module === item.module" :to="item.route" :key="item.title + '-parent'" v-if="showMenuOption(item)"
                                   @click="collapseChildren(item)" class="d-flex">
                    <font-awesome-icon v-if="item.icon" :icon="item.icon" class="mr-1"></font-awesome-icon> {{ item.title }}
                    <font-awesome-icon class="ml-auto" v-if="item.children" :icon="item.expanded ? 'angle-down' : 'angle-left'"></font-awesome-icon>
                </b-list-group-item>
                <b-collapse v-if="item.children" :id="`sidebar-collapse-${index}`" :key="item.title + '-collapse'" v-model="item.expanded">
                    <b-list-group flush class="flex-grow-1">
                        <template v-for="child in item.children">
                            <b-list-group-item :active="$route.meta.module === child.module"
                                               :to="child.route" v-if="!child.rule || $permission.check(child.rule)" :key="child.title + '-children'">
                                <font-awesome-icon v-if="child.icon" :icon="child.icon"></font-awesome-icon> {{ child.title }}
                            </b-list-group-item>
                        </template>
                    </b-list-group>
                </b-collapse>
            </template>
        </b-list-group>
    </b-sidebar>
</template>

<script>
import $permission from '@/js/permission';

export default {
    name: 'Sidebar',
    computed: {
        $permission() {
            return $permission;
        }
    },
    props: {
        menu: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            menuItems: []
        };
    },
    created() {
        let menuItems = [];

        for (let item of this.menu) {
            let menuItem = { ...item };
            menuItem.expanded = true;

            if (menuItem.children && menuItem.children.some(child => child.route ? child.route.name === this.$route.name : false)) {
                menuItem.expanded = true;
            }

            menuItems.push(menuItem);
        }

        this.menuItems = menuItems;

        this.updateBodyClass(true);
    },
    methods: {
        updateBodyClass(isShown) {
            if (isShown) {
                document.body.classList.add('margin-left-sidebar');
                document.body.classList.remove('no-margin-sidebar');
            } else {
                document.body.classList.remove('margin-left-sidebar');
                document.body.classList.add('no-margin-sidebar');
            }
        },
        collapseChildren(item) {
            item.expanded = !item.expanded;
            this.$forceUpdate();
        },
        showMenuOption(item) {
            if (item.children) {
                for (const child of item.children) {
                    if (!child.rule || $permission.check(child.rule)) {
                        return true;
                    }
                }
            } else {
                return !item.rule || $permission.check(item.rule);
            }

            return false;
        }
    }
};
</script>